<template lang="pug">
include /mixins

+b.text-trim-copy
  +e.content
    slot(
      :trimmed-text="trimmedText"
    )
  +b.BUTTON.cc-link--inline.pa--l-xs(
      type="button"
      @click.prevent="$copyToClipboard(value, successMessage ? { success: successMessage } : {})"
    )
      +b.ds-inliner--size_sm
        +e.body
          i-files
</template>
<script>

export default {
  props: {
    value: {
      type: String,
      required: true,
    },
    trim: {
      type: Number,
      default: 55,
    },
    successMessage: {
      type: String,
    },
  },
  data() {
    return {}
  },
  computed: {
    trimmedText() {
      return this.value.length > this.trim
        ? `${this.value.slice(0, this.trim)}...`
        : this.value
    },
  },
}

</script>
