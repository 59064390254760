import {
  baseResource,
} from '../resource/resource'
import { friendlyUrlGenerator } from '../../utils/friendly'
import { prefixAPI } from '../resource/api'

const CITIES_URL_PREFIX = prefixAPI('cities/')
const CITIES_URL_POSTFIX = ''

const STREETS_URL_PREFIX = prefixAPI('streets/')
const STREETS_URL_POSTFIX = ''

const TRANSACTIONS_URL_PREFIX = prefixAPI('market/payments/')
const TRANSACTIONS_URL_POSTFIX = ''

const MERCHANT_TRANSACTIONS_URL_PREFIX = prefixAPI('merchant/companies/{company}/external-payments/list/')
const MERCHANT_TRANSACTIONS_URL_POSTFIX = ''

const CASH_ORDERS_URL_PREFIX = prefixAPI('cash/orders/list/')
const CASH_ORDERS_URL_POSTFIX = ''

const EXCHANGES_URL_PREFIX = prefixAPI('market/exchanges/')
const EXCHANGES_URL_POSTFIX = ''

const order = {
  path: [
    'prefix',
    'label',
  ],
  query: [
    'query',
  ],
}

export const citiesAPI = friendlyUrlGenerator(
  [CITIES_URL_PREFIX, CITIES_URL_POSTFIX],
  baseResource,
  order
)

export const streetsAPI = friendlyUrlGenerator(
  [STREETS_URL_PREFIX, STREETS_URL_POSTFIX],
  baseResource,
  order
)

export const getPaymentsAPI = friendlyUrlGenerator(
  [TRANSACTIONS_URL_PREFIX, TRANSACTIONS_URL_POSTFIX],
  baseResource,
  order
)

export const getMerchantTransactionsAPI = friendlyUrlGenerator(
  [MERCHANT_TRANSACTIONS_URL_PREFIX, MERCHANT_TRANSACTIONS_URL_POSTFIX],
  baseResource,
  order
)

export const getCashTransactionsAPI = friendlyUrlGenerator(
  [CASH_ORDERS_URL_PREFIX, CASH_ORDERS_URL_POSTFIX],
  baseResource,
  order
)

export const getExchangesAPI = friendlyUrlGenerator(
  [EXCHANGES_URL_PREFIX, EXCHANGES_URL_POSTFIX],
  baseResource,
  order
)
