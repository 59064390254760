import { prefixLanguage } from '@utils/urls'

const CHECKOUT_PREFIX = 'checkout';
const p = name => `${CHECKOUT_PREFIX}:${name}`

const CHECKOUT_MERCURYO_PREFIX = p('m')
const pM = name => `${CHECKOUT_MERCURYO_PREFIX}:${name}`

const CHECKOUT_ENDPOINTS = {
  BASE: 'checkout-base',
  CARD: p('card'),
  METHODS: p('methods'),
  MERCURYO: {
    SIGN_IN: pM('sign-in'),
    CHECKING: pM('checking'),
    FAST_TRACK: pM('fast-track'),
    BUY: pM('buy'),
    IN_PROGRESS: pM('in-progress'),
    SUCCESS: pM('success'),
  },
}

export { CHECKOUT_ENDPOINTS }

const CHECKOUT_ROUTES = [
  {
    component: () => import('@app/pages/CheckOutLayout.vue'),
    name: CHECKOUT_ENDPOINTS.BASE,
    path: prefixLanguage('/merchant/order/:id/'),
    props: true,
    children: [
      {
        path: 'card/',
        props: true,
        name: CHECKOUT_ENDPOINTS.CARD,
        redirect: { name: CHECKOUT_ENDPOINTS.MERCURYO.SIGN_IN },
        component: () => import('@app/pages/CheckOut/Card/Layout.vue'),
        children: [
          // {
          //   path: 'methods',
          //   props: true,
          //   name: CHECKOUT_ENDPOINTS.METHODS,
          //   component: () => import('@app/pages/CheckOut/Card/Methods.vue'),
          //   meta: {
          //     fallBack: { name: CHECKOUT_ENDPOINTS.BASE },
          //     resetMethod: true,
          //     isRoute: true,
          //   },
          // },
          {
            path: 'sign-in/',
            props: true,
            name: CHECKOUT_ENDPOINTS.MERCURYO.SIGN_IN,
            component: () => import('@app/pages/CheckOut/Card/Mercuryo/SignIn.vue'),
            meta: {
              fallBack: { name: CHECKOUT_ENDPOINTS.BASE },
              resetMethod: true,
              isRoute: true,
            },
          },
          {
            path: 'checking/',
            props: true,
            name: CHECKOUT_ENDPOINTS.MERCURYO.CHECKING,
            component: () => import('@app/pages/CheckOut/Card/Mercuryo/Checking.vue'),
            meta: {
              isRoute: true,
            },
          },
          {
            path: 'fast-track/',
            props: true,
            name: CHECKOUT_ENDPOINTS.MERCURYO.FAST_TRACK,
            component: () => import('@app/pages/CheckOut/Card/Mercuryo/FastTrack.vue'),
            meta: {
              isRoute: true,
            },
          },
          {
            path: 'pay/',
            props: true,
            name: CHECKOUT_ENDPOINTS.MERCURYO.BUY,
            component: () => import('@app/pages/CheckOut/Card/Mercuryo/Pay.vue'),
            meta: {
              isRoute: true,
            },
          },
          {
            path: 'in-progress/',
            props: true,
            name: CHECKOUT_ENDPOINTS.MERCURYO.IN_PROGRESS,
            component: () => import('@app/pages/CheckOut/Card/Mercuryo/InProgress.vue'),
            meta: {
              isRoute: true,
            },
          },
          {
            path: 'success/',
            props: true,
            name: CHECKOUT_ENDPOINTS.MERCURYO.SUCCESS,
            component: () => import('@app/pages/CheckOut/Card/Mercuryo/Success.vue'),
            meta: {
              isRoute: true,
            },
          },
        ],
      },
      {
        path: prefixLanguage('/cabinet/companies'),
        props: true,
        name: CHECKOUT_ENDPOINTS.LIST,
        component: () => import('@app/merchant/CompaniesList.vue'),
      },
    ],
  },
]

export default CHECKOUT_ROUTES
