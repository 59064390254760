import COMMON_ROUTES from './commons/commons'
import AUTH_ROUTES from './auth/auth'
import VERIFICATION_ROUTES from './verification/verification'
import PROFILE_ROUTES from './profile/profile'
import MERCHANT_ROUTES from './merchant/merchant';
import CHECKOUT_ROUTES from './checkout';

export const ROUTES = [
  ...COMMON_ROUTES,
  ...AUTH_ROUTES,
  ...VERIFICATION_ROUTES,
  ...PROFILE_ROUTES,
  ...MERCHANT_ROUTES,
  ...CHECKOUT_ROUTES,
]
