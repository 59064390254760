import { prefixAPI } from '../resource/api'

import {
  createResource,
  postResource,
  patchResource,
} from '../resource/resource'

const MERCHANT_MODEL = 'merchant/'
const MERCHANT_ORDERS_MERC_MODEL = 'merchant/orders/merc/'

/* MERCHANT */
const MERCHANT_PARTNERSHIP = prefixAPI('partnership/create/', MERCHANT_MODEL)
const MERCHANT_CHECK = prefixAPI('companies/actions/check/', MERCHANT_MODEL)
const MERCHANT_CREATE = prefixAPI('companies/create/', MERCHANT_MODEL)
const MERCHANT_LIST = prefixAPI('companies/list/{?limit,offset}', MERCHANT_MODEL)
const MERCHANT_RETRIEVE = prefixAPI('companies{/id}/receive/', MERCHANT_MODEL)
const MERCHANT_UPDATE = prefixAPI('companies{/id}/update/', MERCHANT_MODEL)
const MERCHANT_SELECTS = prefixAPI('selects/', MERCHANT_MODEL)
const MERCHANT_WALLETS = prefixAPI('companies{/id}/wallet-list/', MERCHANT_MODEL)
const MERCHANT_ORDERS = prefixAPI('orders/list/{?limit,offset,company}', MERCHANT_MODEL)
const MERCHANT_ORDERS_CALCULATE = prefixAPI('orders/actions/calculate/', MERCHANT_MODEL)
const MERCHANT_ORDERS_CURRENCY_CONFIG = prefixAPI('orders/crypto/config{/currency}/', MERCHANT_MODEL)
// const MERCHANT_TRANSACTIONS = prefixAPI('transactions/list/{?limit,offset,company}', MERCHANT_MODEL)
const MERCHANT_ORDER_PAYMENT = prefixAPI('orders{/id}/action/payment/', MERCHANT_MODEL)
const MERCHANT_ORDER_PAYMENT_TEST = prefixAPI('orders{/id}/action/test/', MERCHANT_MODEL)
const MERCHANT_TO_BALANCE = prefixAPI('companies{/id}/actions/to-balance/', MERCHANT_MODEL)
const MERCHANT_WITHDRAW = prefixAPI('companies{/id}/actions/withdraw/', MERCHANT_MODEL)
const MERCHANT_PAYMENT_HISTORY = prefixAPI('companies{/id}/payments/list/{?limit,offset,wallet,category,status,ordering,date_before,date_after}', MERCHANT_MODEL)
const MERCHANT_CURRENCIES = prefixAPI('currencies/list/', MERCHANT_MODEL)

// Merc
const MERCHANG_MERC_AUTH = prefixAPI('auth/', MERCHANT_ORDERS_MERC_MODEL)
const MERCHANG_MERC_BUY_RATES = prefixAPI('buy-rates/', MERCHANT_ORDERS_MERC_MODEL)
const MERCHANG_MERC_BUY = prefixAPI('buy/', MERCHANT_ORDERS_MERC_MODEL)
const MERCHANG_MERC_BUY_CARD = prefixAPI('buy-card/', MERCHANT_ORDERS_MERC_MODEL)
const MERCHANG_MERC_CARD_LIMITS = prefixAPI('card-limits/', MERCHANT_ORDERS_MERC_MODEL)
const MERCHANG_MERC_KYC_STATUS = prefixAPI('kyc-status/', MERCHANT_ORDERS_MERC_MODEL)
const MERCHANG_MERC_KYC_VERIFICATION = prefixAPI('kyc-verification/', MERCHANT_ORDERS_MERC_MODEL)
const MERCHANG_MERC_PAYMENT = prefixAPI('payment/', MERCHANT_ORDERS_MERC_MODEL)

/* MERCHANT */

export const merchantPartnershipCreate = createResource(
  MERCHANT_PARTNERSHIP,
  postResource
)

export const merchantCheckCode = createResource(
  MERCHANT_CHECK,
  postResource
)

export const merchantCreateCompany = createResource(
  MERCHANT_CREATE,
  postResource
)

export const merchantCompaniesList = createResource(
  MERCHANT_LIST
)

export const merchantPaymentsList = createResource(
  MERCHANT_PAYMENT_HISTORY
)

export const merchantCompanyRetrieve = createResource(
  MERCHANT_RETRIEVE
)

export const merchantCompanyUpdate = createResource(
  MERCHANT_UPDATE,
  patchResource
)

export const merchantSelects = createResource(
  MERCHANT_SELECTS
)

export const merchantWallets = createResource(
  MERCHANT_WALLETS
)

export const merchantOrders = createResource(
  MERCHANT_ORDERS
)

export const merchantOrderPay = createResource(
  MERCHANT_ORDER_PAYMENT,
  postResource
)

export const merchantOrderTestPay = createResource(
  MERCHANT_ORDER_PAYMENT_TEST,
  postResource
)

export const merchantOrderCryptoCalculate = createResource(
  MERCHANT_ORDERS_CALCULATE,
  postResource
)

export const merchantOrderCurrencyConfig = createResource(
  MERCHANT_ORDERS_CURRENCY_CONFIG
)

export const merchantToBalanceAPI = createResource(
  MERCHANT_TO_BALANCE,
  postResource
)

export const merchantWithdrawAPI = createResource(
  MERCHANT_WITHDRAW,
  postResource
)

export const merchantCurrenciesAPI = createResource(
  MERCHANT_CURRENCIES
)

/* MERC */
export const mercAuthAPI = createResource(
  MERCHANG_MERC_AUTH,
  postResource
)
export const mercBuyRatesAPI = createResource(
  MERCHANG_MERC_BUY_RATES,
  postResource
)
export const mercBuyAPI = createResource(
  MERCHANG_MERC_BUY,
  postResource
)
export const mercBuyCardAPI = createResource(
  MERCHANG_MERC_BUY_CARD,
  postResource
)
export const mercCardLimitsAPI = createResource(
  MERCHANG_MERC_CARD_LIMITS,
  postResource
)
export const mercKycStatusAPI = createResource(
  MERCHANG_MERC_KYC_STATUS,
  postResource
)
export const mercKycVerificationAPI = createResource(
  MERCHANG_MERC_KYC_VERIFICATION,
  postResource
)
export const mercPaymentAPI = createResource(
  MERCHANG_MERC_PAYMENT,
  postResource
)
