<template lang="pug">
include ../Bemto
+b.top-up-modal.relative
  ui-loading(
    v-if="loading"
    size="2xl"
  )
  +b.close-btn(
    @click.prevent='$emit("close")'
  )
    +e.cross &#10005;
  +b.P.text.--weight-bold.--size-lg.--size-2lg-sm.ma--b-2lg.ma--b-none-sm {{ _('Поповнення балансу') }}
  +b.VALIDATION-OBSERVER.auth-form(
    ref='validator'
    v-slot="{ valid }"
    tag='form'
  )

    +b-context('top-up-modal')
      +e.top-up-form.ma--t-2sm-sm
        row(appearance="spaced" space="lg")
          cell(cols="12")
            d-control-multiselect(
              :input-label="_('Currency type')"
              name='currencyType'
              rules='required'
              :allow-empty="false"
              :options="paymentsSelects.currencyTypes || []",
              @select="onChangeCurrencyType"
              track-by="value"
              label='displayName'
              v-model='form.currencyType'
              :disabled="!!cryptoAddress"
            )

          cell(
            :cols="['12', '6-md']"
          )
            d-control-input(
              :input-label="_('Вказати суму')"
              :rules="`required`"
              name='amount'
              v-model='form.amount'
              @input="debounceCalculate(false)"
              :disabled="!!cryptoAddress"
            )
            //- :rules="`required|min_value:${minAmount}|max_value:${maxAmount}`"
          cell(
            :cols="['12', '6-md']"
          )
            d-control-multiselect(
              :input-label="_('Currency')"
              name='currency'
              rules='required'
              :allow-empty="false"
              :options="currenciesList",
              :searchable="true"
              :taggable="false"
              track-by="code"
              label='code'
              v-model='form.currency'
              :disabled="!!cryptoAddress || !form.currencyType"
              @select='getCryptoConfig'

            )
          cell(cols="12")
            radio-bar(
              v-if='cryptoConfig.networks'
              :name="'network'"
              :radio-data='cryptoConfig.networks'
              :setValue='form.network'
              @radio-value="setNetwork($event)"
              :disabled="!!cryptoAddress"
            )

          //- template(
          //-   v-if="form.currencyType && 1 === form.currencyType.value && form.currency && form.currency.code"
          //- )
            cell(cols="12")
              row(
                align="end"
                space='sm'
              )
                cell(cols="narrow")
                  +b.P.text.--color-blue-grey.--size-3xxs {{ _('Bank fee') }}
                cell(cols="auto")
                  +b.ma--b-2xxs.dashed-border
                cell(cols="narrow")
                  +b.P.text.--size-xs {{ (paymentConfig.bankCommission || 0) + ' ' + form.currency.code }}

            cell(cols="12")
              row(
                align="end"
                space='sm'
              )
                cell(cols="narrow")
                  +b.P.text.--color-blue-grey.--size-3xxs {{ siteCommissionConfig }}
                cell(cols="auto")
                  +b.ma--b-2xxs.dashed-border
                cell(cols="narrow")
                  +b.P.text.--size-xs {{ siteCommission + ' ' + form.currency.code }}
          template(
            v-if="form.currencyType && 0 === form.currencyType.value && form.currency && form.currency.code"
          )
            cell(cols="12")
              row(
                align="end"
                space='sm'
              )
                cell(cols="narrow")
                  +b.P.text.--color-blue-grey.--size-3xxs {{ `${form.currency.code}/${currentCurrency}` }}
                cell(cols="auto")
                  +b.ma--b-2xxs.dashed-border
                cell(cols="narrow")
                  +b.P.text.--size-xs {{ Number(Number(currentCryptoRate || cryptoConfig.reverseRate || 0).toFixed(8)) }}
            //- cell(
            //-   v-if="networkCommissionConfig.withdrawDefaultCommission && networkCommissionConfig.rateCommission"
            //-   cols="12"
            //- )
              row(
                align="end"
                space='sm'
              )
                cell(cols="narrow")
                  +b.P.text.--color-blue-grey.--size-3xxs {{ _('Network Fee') }} {{ ` ${form.currency.code}/${currentCurrency}`  }}
                cell(cols="auto")
                  +b.ma--b-2xxs.dashed-border
                cell(cols="narrow")
                  +b.P.text.--size-xs {{ Number(Number(networkCommissionConfig.withdrawDefaultCommission).toFixed(8)) + '/' + Number(Number(networkCommissionConfig.rateCommission).toFixed(8)) }}
            cell(cols="12")
              row(
                align="end"
                space='sm'
              )
                cell(cols="narrow")
                  +b.P.text.--color-blue-grey.--size-3xxs {{ siteCommissionConfig }}
                cell(cols="auto")
                  +b.ma--b-2xxs.dashed-border
                cell(cols="narrow")
                  +b.P.text.--size-xs {{ siteCommission + ' ' + (form.currency && form.currency.code || currentCurrency) }}
            cell(cols="12")
              row(
                align="end"
                space='sm'
              )
                cell(cols="narrow")
                  +b.P.text.--color-blue-grey.--size-3xxs {{ _('Conversion commission') + ` ${conversionCommission && conversionCommission.percent || cryptoConfig && cryptoConfig.siteExchangeFee || 0}%` }}
                cell(cols="auto")
                  +b.ma--b-2xxs.dashed-border
                cell(cols="narrow")
                  +b.P.text.--size-xs {{ (conversionCommission && conversionCommission.amount || 0) + ' ' + form.currency.code }}

            //- cell(cols="12")
              row(
                :justify="['between']"
                space='none'
              )
                cell
                  +b.P.text.--size-3xxs.--weight-medium {{ _('Сума поповнення') }}
                cell
                  +b.P.text.--size-2sm.--weight-bold {{ total }}
            cell(cols="12")
              d-control-input.ma--t-2xs(
                :input-label="_('Сума в USDT')"
                name='in-usdt'
                v-model="form.value"
                @input="debounceCalculate(true)"
                :disabled="!!cryptoAddress"
              )
          template(
            v-if="!!cryptoAddress"
          )
            cell(
              cols="12"
            )
              //- rules='required'
              d-control-input.ma--b-xs.address-input(
                :key="form.amount"
                :input-label="_('You need to pay')"
                v-model='form.amount'
                v-clipboard:copy="`${form.amount}`"
                v-clipboard:success="() => $notify({ text: _('Amount copied to clipboard'), type: 'success' })"
                readonly
                :append-icon="['icon', 'icon-files']"
              )
            cell(
              cols="12"
            )
              //- rules='required'
              d-control-input.ma--b-xs.address-input(
                :input-label="_('Адреса гаманця криптовалюти')"
                v-model='cryptoAddress'
                v-clipboard:copy="cryptoAddress"
                v-clipboard:success="() => $notify({ text: _('Address copied to clipboard'), type: 'success' })"
                readonly
                :append-icon="['icon', 'icon-files']"
              )
            cell(
              cols="12"
              v-if="memo"
            )
              //- rules='required'
              d-control-input.ma--b-xs(
                :input-label="_('Memo exchange')"
                v-model='memo'
                v-clipboard:copy="memo"
                v-clipboard:success="() => $notify({ text: _('Memo copied to clipboard'), type: 'success' })"
                readonly
                :append-icon="['icon', 'icon-files']"
              )
              //- d-control-input(
              //-   v-if='currencyConfig && currencyConfig.paymentId'
              //-   :input-label="_('Payment ID')"
              //-   v-model='form.metadata.paymentId'
              //- )
          cell(
            cols="12"
            v-if="!!cryptoAddress"
          )
            row(
              justify="center"
            )
              cell(cols="narrow")
                +b.canvas-code#canvas.CANVAS(
                  ref='canvas'
                )

    +b.BUTTON.el-btn.--default.ma--t-lg(
      v-if="!!cryptoAddress"
      :style="{width: '100%', padding: '12px 0'}"
      @click.prevent='$emit("close")'
    )
      +b.P.text.--weight-black {{ _('Close') }}
    +b.BUTTON.el-btn.--default.ma--t-lg(
      v-else
      :style="{width: '100%', padding: '12px 0'}"
      @click.prevent='prepareData(valid)'
    )
      +b.P.text.--weight-black {{ _('Далі') }}
</template>

<script>
import QRCode from 'qrcode'
import { clone } from 'ramda'
import debounce from 'lodash.debounce'
import { mapActions, mapGetters } from 'vuex'
import LoadingStateMixin from '@aspectus/vue-loading-state-mixin'
import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin'
/* eslint-disable import/no-extraneous-dependencies */
import {
  replenishAPI,
  replenishFiatAPI,
  replenishCalculateAPI,
  replenishCommissionAPI,
  transactionsSelectsAPI,
  getCurrenciesListAPI,
  exchangeConfigAPI,
} from '@requests/services/market.service'
import { DEFAULT_CURRENCY } from '@/consts'

export default {
  name: 'topUpModal',
  mixins: [FormMixin, LoadingStateMixin],
  props: {
    info: {},
  },
  data() {
    return {
      currentCurrency: DEFAULT_CURRENCY,
      currentCryptoRate: null,
      difference: null,
      qtyWalletError: null,
      walletError: null,
      cryptoAddress: null,
      memo: null,
      conversionCommission: null,
      paymentsSelects: {},
      currenciesList: [],
      cryptoConfig: {},
      isAuth: window.isAuthenticated,
      paymentConfig: {},
      form: {
        currencyType: null,
        currency: null,
        network: undefined,
        amount: '',
        reverse: false,
        redirectTo: window.location.origin,
      },
    }
  },
  computed: {
    ...mapGetters('account', {
      userConfiguration: 'getAccountConfiguration',
    }),
    ...mapGetters('selects', {
      getSingleSelect: 'getSingleSelect',
    }),
    siteCommissionConfig() {
      return `${this._('Deal fee')} ${
        this.paymentConfig.dealFee || 0
      }%`
    },
    siteCommission() {
      return Number(this.percentFunction(this.paymentConfig.dealFee || 0).toFixed(8))
      // .toFixed(2)
    },
    networkCommissionConfig() {
      let result = {}

      if (this.form.network) {
        const currentNetwork = this.cryptoConfig.networks.find(
          el => el.name === this.form.network
        )
        result = {
          rateCommission: currentNetwork.rateCommission,
          minCrypto: currentNetwork.commission,
          withdrawDefaultCommission: currentNetwork.withdrawDefaultCommission,
        }
      }
      return result
    },
    total() {
      const total =
        Number(this.form.amount) +
        Number(this.paymentConfig.bankCommission) +
        Number(this.siteCommission)
      return (total || 0).toFixed(2)
    },
  },
  mounted() {
    this.getPaymentsSelects()
    this.debounceCalculate = debounce(this.calculate, 700)
  },
  methods: {
    ...mapActions('account', ['accountRequest']),
    onChangeCurrencyType() {
      this.form.currency = null
      this.cryptoConfig = []
      this.form.network = undefined
      this.$nextTick(() => {
        this.getCurrenciesList(this.form.currencyType.value)
      })
    },
    setNetwork(value) {
      this.form.network = value
      this.calculate(this.form.reverse)
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      this.$refs.validator.setErrors(parsed)
    },
    async prepareData(valid) {
      this.codeVisible = true
      this.$refs.validator.validate()
      if (!valid) return
      this.submit(valid, this.form)
    },
    calculate(reverse) {
      if (0 === this.form.currencyType.value) {
        this.form.reverse = reverse
        const data = {
          amount: (reverse ? this.form.value : this.form.amount) || '',
          currency: this.form.currency.code,
          network: this.form.network,
          reverse,
        }
        this.$load(replenishCalculateAPI.execute({}, data)).then(({ data: { item } }) => {
          if (reverse) {
            this.form.amount = Math.ceil((Number(item.amount) + Number.EPSILON) * 100) / 100 || 0
          } else {
            this.$set(this.form, 'value', Number(Number(item.amount).toFixed(8)))
            this.$nextTick(() => {
              this.form.value = Number(Number(item.amount).toFixed(8))
            })
          }
          this.conversionCommission = item.conversionCommission
          this.currentCryptoRate = item.currentRate
        })
      }
    },
    getPaymentsSelects() {
      transactionsSelectsAPI.execute().then(({ data: { item } }) => {
        this.paymentsSelects = item;
        [this.form.currencyType] = item.currencyTypes
        this.onChangeCurrencyType()
      })
    },
    getCurrenciesList(type) {
      console.log('type', type);
      getCurrenciesListAPI.execute({ type, for_sale: true }).then(({ data: { items } }) => {
        this.currenciesList = items
        const [firstCurrency] = items
        this.form.currency = firstCurrency
        this.getCryptoConfig(this.form.currency)
      })
    },

    getCryptoConfig(crypto) {
      this.$nextTick(() => {
        this.form.currency = crypto
        if (0 === this.form.currencyType.value && crypto?.code) {
          this.getCommission(crypto)
          this.$load(exchangeConfigAPI.execute({ currency: crypto.code })).then(
            ({ data: { item } }) => {
              this.cryptoConfig = item
              this.form.network = item.networks[0].name
              if (this.form.reverse && this.form.value) {
                this.calculate(true)
              } else if (!this.form.reverse && this.form.amount) {
                this.calculate(false)
              }
            }
          )
        }
      })
    },
    getCommission(currency = this.form.currency) {
      replenishCommissionAPI.execute({ currency: currency?.code }).then(({ data: { item } }) => {
        this.paymentConfig = item
      })
    },
    percentFunction(percent) {
      return ((this.form.amount * percent + Number.EPSILON) * 100) / 100 / 100
    },
    send(data) {
      const resource = 1 === this.form.currencyType.value ? replenishFiatAPI : replenishAPI
      const transformed = clone(data)
      transformed.currency = data.currency.code
      transformed.fiatCurrency = data.currency.code
      transformed.lang = 'en'
      transformed.redirectUrl = window.location.href
      return this.$load(resource
        .execute({}, transformed))
        .then(({ data: { item } }) => {
          if (item.url) {
            window.location.href = item.url
          } else {
            this.cryptoAddress = item.address
            this.memo = item.memo
            this.generateQr(item.address)
          }
        })
    },
    generateQr(address) {
      const options = {
        version: 8,
        mode: 'numeric',
        scale: 4,
        maskPattern: 0,
        color: {
          dark: '#000000',
          light: '#F8F7F8',
        },
      }
      this.$nextTick(() => {
        QRCode.toCanvas(this.$refs.canvas, address, options, error => {
          if (error) {
            console.error(error)
          }
        })
      })
    },
  },
}
</script>
